import React, { Component } from 'react'
import Text from '../Text'

import './style.css'

class TabsCard extends Component {
    constructor (props) {
        super(props)

        this.state = {
            currentHeading: this.props.data.details[0].title,
            currentDetails: this.props.data.details[0].details
        }

        this.changeActiveNavigation = this.changeActiveNavigation.bind(this)
    }

    changeActiveNavigation(e) {
        const value = parseInt(e.target.id.split('nav-button-')[1])
        let liButtons = document.querySelectorAll('.tabs-card-navigation button')
        for (let i = 0; i < liButtons.length; i++) {
            liButtons[i].classList.remove('active-nav')
            if(i == value) {
                liButtons[i].classList.add('active-nav')
            }
        } 

        this.setState({
            currentHeading: this.props.data.details[value].title,
            currentDetails: this.props.data.details[value].details
        })
    }

    render () {
        let style = { justifyContent: 'center' }
        if (this.props.data.textAlign == 'left') {
            style = {
                justifyContent: 'flex-start'
            }
        }

        return (
            <div className='tabs-card'>
                <nav className="tabs-card-navigation">
                    <ul className="nav flexStart">
                        {this.props.data.titles.map((title, index) => (
                            <li key={`li-${index}`}><button id={`nav-button-${index}`} onClick={this.changeActiveNavigation}>{title}</button></li> 
                        ))}
                    </ul>
                </nav>
                <div className='tab-details' style={style}>
                    <Text data={{
                        title: this.state.currentHeading,
                        details: this.state.currentDetails
                    }} />
                </div>
            </div>
        )
    }
}

export default TabsCard