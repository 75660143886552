import React, { Component } from 'react'
import Text from '../Text'

import './style.css'

class NavigationList extends Component {
    render () {
        let activeClass = ''
        if(this.props.currentNav == this.props.index){ 
            activeClass += 'active-nav' 
        }
      
        return (
            <li className={activeClass} id={this.props.index} onClick={this.props.updateDetails}>
                {this.props.heading} 
            </li>
        )
    }
}
  

class TabsCardWithNavigationOnLeft extends Component {
    constructor (props) {
        super(props)

        this.state = {
            currentNav: 0
        }
        this.updateDetailsData = this.updateDetailsData.bind(this)
    }

    getNavigationList() {
        let navigationList = this.props.data.navList.map((feature, index) => {
            return <NavigationList
                key={'nl-' + index}
                heading={feature}
                index={index}
                currentNav={this.state.currentNav}
                updateDetails={this.updateDetailsData}
            />
        })
    
        return navigationList;
    }

    updateDetailsData (e) {
        const id = parseInt(e.target.id)

        this.setState({
            currentNav: id
        });
    }

    render () {
        return (
            <div className='tabs-card-with-nav-on-left'>
                <div className='nav-div'>
                    <ul>
                        {this.getNavigationList()}
                    </ul>
                </div>
                <div className='details-div'>
                    {this.props.data.details[this.props.data.navList[this.state.currentNav]].map((detail, index) => (
                        <Text key={`text-${index}`} data={{
                            title: detail.title,
                            details: detail.details
                        }} />
                    ))}
                </div>
            </div>
        );
    }
}

export default TabsCardWithNavigationOnLeft