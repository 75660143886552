import React, { Component } from 'react'
import Menu from '../Menu'
import Logo from '../Logo'
import './style.css'

export default class extends Component {
  render () {
    const props = this.props.data
    return (
      <div className={`zc-menu-bar ${props.sticky ? 'sticky' : ''}`}>
        {props.logo && <Logo src={props.logo.src} alt={props.logo.alt || ''} />}
        {props.menu && <Menu list={props.menu} />}
      </div>
    )
  }
}
