import React, { Component } from 'react'
import Text from '../Text'
import Banner from '../Banner'
import Image from '../Image'

import './style.css'

class BannerWithTextOnTop extends Component {
  render () {
    const props = this.props.data

    return (
      <div className='zc-banner-with-text-on-top'>
        <Text data={{
          title: props.title,
          subtitle: props.subtitle,
          details: props.details
        }} />
        <Banner data={{
          image: props.src
        }} />
      </div>
    )
  }
}

export default BannerWithTextOnTop
