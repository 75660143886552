import React, { Component } from 'react'
import SocialLinks from '../SocialMediaLinks'
import NavigationBlockCollection from '../NavigationBlockCollection'
import Text from '../Text'
import Logo from '../Logo'

import './style.css'

const componentMapping = {
  'NavigationBlockCollection': NavigationBlockCollection,
  'Text': Text,
  'Logo': Logo,
  'SocialMediaLinks': SocialLinks
}

class FooterCentre extends Component {
  render () {
    const props = this.props.data
    const primarySection = props.primarySection
    const secondarySection = props.secondarySection
    return (
      <section className='zc-footer-centre'>
        <div className='main-section'>
          {primarySection.data.map((component, i) => {
            const Component = componentMapping[component.type]
            return (
              <Component key={`footer-primary-${i}`} {...component} />
            )
          })}
        </div>
        <div className='secondary-section'>
          {secondarySection && secondarySection.data.map((component, i) => {
            const Component = componentMapping[component.type]
            return (
              <Component key={`footer-secondary-${i}`} {...component} />
            )
          })}
        </div>
      </section>
    )
  }
}

export default FooterCentre
