import React, { Component } from 'react'
import AliceCarousel from 'react-alice-carousel'
import ImageCard from '../ImageCard'

import './style.css'
import "react-alice-carousel/lib/alice-carousel.css"

class ImageCardCarousel extends Component {
    render () {
        let responsive = {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1024: {
                items: 3
            }
        }
        responsive = this.props.responsive ? this.props.responsive : responsive
    
        return (
            <AliceCarousel
                responsive={responsive}
                dotsDisabled={this.props.dotsDisabled}
                buttonsDisabled={this.props.buttonsDisabled}
                onSlideChanged={this.props.onSlideChanged}
                startIndex = {this.props.startIndex}
                duration={this.props.duration}
                autoPlay={this.props.autoPlay}
                fadeOutAnimation={this.props.fadeOutAnimation}
                autoPlayInterval={this.props.autoPlayInterval}
                autoPlayDirection={this.props.autoPlayDirection}
                mouseDragEnabled={this.props.mouseDragEnabled}
            >
                {this.props.data.length > 0 &&
                    this.props.data.map((card, index) => (
                        <ImageCard key={`ic-${index}`} data={ card } />
                    ))
                }
            </AliceCarousel>
        )
    }
}

export default ImageCardCarousel