import React, { Component } from 'react'
import AliceCarousel from 'react-alice-carousel'
import Image from '../Image'

import './style.css'
import "react-alice-carousel/lib/alice-carousel.css"

class ImageCarousel extends Component {
    render () {
        return (
            <div className='image-carousel'>
                <AliceCarousel>
                    {this.props.data.length > 0 &&
                        this.props.data.map((image, index) => (
                            <Image 
                                key={`ic-${index}`} 
                                src={ image } 
                                alt={`image-${index}`} />
                        ))
                    }
                </AliceCarousel>
            </div>
        )
    }
}

export default ImageCarousel