import React, { Component } from 'react'
import Text from '../Text'
import Image from '../Image'

import './style.css'

class ImageWithText extends Component {
  render () {
    const props = this.props.data
    return (
      <div className={`zc-image-with-text ${props.align === 'left' ? 'tl' : ''}`}>
        <Image src={props.src} alt={props.alt} />
        <Text data={{
          title: props.title,
          subtitle: props.subtitle,
          details: props.details
        }} />
      </div>
    )
  }
}

export default ImageWithText
