import React, { Component } from 'react'
import './style.css'

export default class SocialMediaLinks extends Component {
  render () {
    const props = this.props.data
    return (
      <div className='zc-social-media-links'>
        <ul className={`social-links ${this.props.align === 'centre' ? 'flexcentre' : null}`}>
          {props.fb && <li><a className='facebook-link' href={`https://www.facebook.com/${props.fb}`} target='_blank'>facebook</a></li>}
          {props.linkedIn && <li><a className='linkedin-link' href={`https://www.linkedin.com/${props.linkedIn}`} target='_blank'>linkedIn</a></li>}
          {props.twitter && <li><a className='twitter-link' href={`https://twitter.com/${props.twitter}`} target='_blank'>twitter</a></li>}
        </ul>
      </div>
    )
  }
}
