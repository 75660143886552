import React, { Component } from 'react'
import Text from '../Text'
import Button from '../Button'
import Image from '../Image'

import banner from '../images/group-27.svg'

import './style.css'

class BannerWithMultipleButtons extends Component {
    render () {
        const props = this.props.data

        if(!Object.keys(props).length) {
            return <Image src={banner} alt='default-banner' />
        }
        
        const style = { 
            backgroundImage: `url(${props.src})`
        };

        return (
            <a href={`/${props.link}`}>
            <div className='zc-banner-with-multiple-buttons' style={style}>
                <div className='zc-banner-content-div'>
                    <Text data={{
                        title: props.title,
                        fontSizeForTitle: props.fontSizeForTitle,
                        textColorForTitle: props.textColorForTitle,
                        subtitle: props.subtitle,
                        details: props.details,
                        textColorForDescription: props.textColorForDescription,
                        fontSizeforDescription: props.fontSizeforDescription
                    }} />
                    <div className='buttons-div'>
                        <Button data={{
                            value: props.action1,
                            callback: props.callback1,
                            link: props.link1
                        }} />
                        <Button data={{
                            value: props.action2,
                            callback: props.callback2,
                            link: props.link2
                        }} /> 
                    </div>
                </div>
            </div>
            </a>
        )
    }
}

export default BannerWithMultipleButtons
