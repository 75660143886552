import React, { Component } from 'react'
import ImageCard from '../ImageCard'
import Text from '../Text'

import './style.css'

class ContentCardWithImageCards extends Component {
    render () {
        const props = this.props.data
        
        return (
            <div className='content-card-with-static-image-cards'>
                <Text data={{
                    title: props.title,
                    details: props.details
                }} />
                <div className='cc-multiple-image-cards'>
                    {props.imageCardsData.length > 0 && props.imageCardsData.map((imageCard, index) => (
                        <ImageCard data={{
                            src: imageCard.src,
                            title: imageCard.title,
                            details: imageCard.details
                        }} />
                    )) }
                </div>
            </div>
        );
    }
}

export default ContentCardWithImageCards