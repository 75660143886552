import React, { Component } from 'react'
import SocialLinks from '../SocialMediaLinks'
import NavigationBlockCollection from '../NavigationBlockCollection'
import Image from '../Image'
import Text from '../Text'

import './style.css'

const componentMapping = {
  'NavigationBlockCollection': NavigationBlockCollection,
  'Image': Image,
  'Text': Text,
  'SocialMediaLinks': SocialLinks
}
class FooterNavigation extends Component {
  render () {
    const props = this.props.data
    const primarySection = props.primarySection
    const secondarySection = props.secondarySection
    const navigationCollection = primarySection.data.filter((component) => component.type === 'NavigationBlockCollection')[0]
    const rest = primarySection.data.filter((component) => component.type !== 'NavigationBlockCollection')
    return (
      <section className='zc-footer-navigation'>
        <div className={`main-section ${props.primarySection.align}`}>
          <NavigationBlockCollection key={`footer-navigation-block`} {...navigationCollection} />
          <div>
            {rest.map((component, i) => {
              const Component = componentMapping[component.type]
              return <Component key={`footer-primary-${i}`} {...component} />
            })}
          </div>
        </div>
        <div className='secondary-section'>
          {props.secondarySection && secondarySection.data.map((component, i) => {
            const Component = componentMapping[component.type]
            return <Component key={`footer-secondary-${i}`} {...component} />
          })}
        </div>
      </section>
    )
  }
}
export default FooterNavigation
