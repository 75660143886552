import React, { Component } from 'react'
import Text from '../Text'
import TextAsList from '../TextAsList'
import Image from '../Image'

import './style.css'

class ImageCard extends Component {
  render () {
    const props = this.props.data

    const Component = props.textType == 'TextAsList' ? TextAsList : Text

    return (
      <div className={`image-card ${props.align === 'left' ? 'align-left' : ''}`}>
        <Image src={props.src} alt={props.alt} />
        <Component data={{
          title: props.title,
          subtitle: props.subtitle,
          details: props.details
        }} />
      </div>
    )
  }
}

export default ImageCard
