import React, { Component } from 'react'
import Arrow from '../Arrow'
import BannerWithText from '../BannerWithText'
import Image from '../Image'

import './style.css'

class BannerCarousel extends Component {
    constructor (props) {
        super(props)
        this.bannerDetails = this.props.data
        this.state = {
            currentImageIndex: 0
        };
        this.nextSlide = this.nextSlide.bind(this);
        this.previousSlide = this.previousSlide.bind(this);
    }

    previousSlide () {
        const lastIndex = this.bannerDetails.length - 1;
        const { currentImageIndex } = this.state;
        const shouldResetIndex = currentImageIndex === 0;
        const index =  shouldResetIndex ? lastIndex : currentImageIndex - 1;
    
        this.setState({
          currentImageIndex: index
        });
    }

    nextSlide () {
        const lastIndex = this.bannerDetails.length - 1;
        const { currentImageIndex } = this.state;
        const shouldResetIndex = currentImageIndex === lastIndex;
        const index =  shouldResetIndex ? 0 : currentImageIndex + 1;
    
        this.setState({
          currentImageIndex: index
        });
    }

    render () {
        const props = this.props.data

        return (
            <div className="carousel">
                <Arrow
                    direction="left"
                    clickFunction={ this.previousSlide }
                    glyph="&#9664;" />

                <BannerWithText data={ this.bannerDetails[this.state.currentImageIndex] } />

                <Arrow
                    direction="right"
                    clickFunction={ this.nextSlide }
                    glyph="&#9654;" />
            </div>
        )
    }
}

export default BannerCarousel
