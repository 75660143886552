import React, { Component } from 'react'
import Text from '../Text'
import Image from '../Image'

import './style.css'

export default class extends Component {
  render () {
    const formatItem = function (item) {
      return {
        title: item.title,
        subtitle: item.subtitle,
        details: item.details
      }
    }
    return (
      <section className='zc-zigzag'>
        <h2 className='z-heading'>{this.props.data.title}</h2>
        <div>
          {this.props.data.items.map((item, i) => (
            <article key={`item-${i}`} className={(i % 2) ? 'even' : 'odd'}>
              <Image src={item.image} alt={item.alt} />
              <Text data={formatItem(item)} />
            </article>
          ))}
        </div>
      </section>
    )
  }
}
