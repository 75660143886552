import React, { Component } from 'react'
import Button from '../Button'
import Text from '../Text'
import './style.css'

import bannerDefaultImage from '../images/group-25.svg'
import bannerWithMultipleButtonsDefaultImage from '../images/group-27.svg'

class Banner extends Component {
  render () {
    const props = this.props.data
    const textAlign = props.textAlign === 'right' ? 'alignRight' : props.textAlign === 'left' ? 'alignLeft' : 'center' || 'center'
    if (!Object.keys(props).length) {
      return <img src={bannerDefaultImage} alt='default-banner' />
    } else if (Object.keys(props).length === 1 && props.buttons && props.buttons.length === 0) {
      return <img src={bannerWithMultipleButtonsDefaultImage} alt='default-banner-with-multiple-buttons' />
    }

    return (
      <a href={`/${props.link}`}>
      <div className='banner' style={{ backgroundImage: `url(${props.src})` }}>
        <div className={`banner-content-div ${textAlign}`}>
          <Text data={{
            title: props.title,
            details: props.details
          }} />
          {
            props.buttons && props.buttons.length > 0 && <div className='banner-buttons-container'>{props.buttons.map((button, index) => (
              <Button key={`button ${index}`} data={{
                value: button.action,
                link: button.link,
                callback: button.callback
              }} />
            ))}</div>
          }
        </div>
      </div>
      </a>
    )
  }
}

export default Banner
