import React, { Component } from 'react'
import Image from '../Image'

import './style.css'

class ImageCardWithTitleOnRight extends Component {
    render () {
        const props = this.props.data

        return (
            <div className='image-card-with-title-on-right'>
                <div className='image-with-text'>
                    <Image src={props.src} alt={props.alt} />
                    <div className='title'>
                        <h2>{props.title}</h2>
                    </div>
                </div>
                <div className='description'>
                    <p>{props.details}</p>
                </div>
            </div>
        )
    }
}

export default ImageCardWithTitleOnRight