import React, { Component } from 'react'
import ImageCard from '../ImageCard'
import ImageCardCarousel from '../ImageCardCarousel'

import './style.css'

class ImageCardCarouselOnMultipleItems extends Component {
    constructor (props) {
        super(props)

        this.state = {
            currentTitle: this.props.data[0].title,
            currentDetails: this.props.data[0].details,
            src: this.props.data[0].src,
            startIndex: 0
        }

        this.onSlideChanged = this.onSlideChanged.bind(this)
    }

    onSlideChanged (e) {
        this.setState({
            currentTitle: this.props.data[e.item].title,
            currentDetails: this.props.data[e.item].details,
            src: this.props.data[e.item].src,
            startIndex: e.item
        });
    }

    render () {
        const props = this.props.data

        const responsive = {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1024: {
                items: 2
            }
        }

        return (
            <div className='ic-carousel-with-multiple-items'>
                <ImageCard data={{
                    title: this.state.currentTitle,
                    details: this.state.currentDetails,
                    src: this.state.src
                }} />
                <div className='ic-carousel'>
                    <ImageCardCarousel
                        responsive={responsive}
                        dotsDisabled={true}
                        buttonsDisabled={false}
                        onSlideChanged={this.onSlideChanged}
                        startIndex = {this.state.startIndex}
                        data={props}
                    />
                </div>
            </div>
        )
    }
}

export default ImageCardCarouselOnMultipleItems