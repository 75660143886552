import React, { Component } from 'react'
import NavigationBlock from '../NavigationBlock'

import './style.css'

class NavigationBlockCollection extends Component {
  render () {
    const props = this.props.data
    return (
      <section className={`zc-navigation-block-collection ${this.props.align} ${this.props.display}`}>
        {props.collection.map((item, index) => (
          <div key={`navigation-block-${index}`}>
            <NavigationBlock
              data={{
                title: item.title,
                links: item.links,
                link: item.link
              }}
            />
          </div>
        ))}
      </section>
    )
  }
}
export default NavigationBlockCollection
