import React, { Component } from 'react'
import Text from '../Text'
import TextAsList from '../TextAsList'
import Image from '../Image'

import './style.css'

class ContentCard extends Component {
    render () {
        const props = this.props.data

        const style = {
            flexFlow: props.textAlign == 'left' ? 'row' : 'row-reverse'
        } 
        
        const Component = props.textType == 'TextAsList' ? TextAsList : Text
        
        return (
            <div className='content-card' style={style}>
                <Component data={{
                    title: props.title,
                    details: props.details
                }} />
                <Image src={props.src} />
            </div>
        );
    }
}

export default ContentCard