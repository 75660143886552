import React, { Component } from 'react'
import SocialMediaLinks from '../SocialMediaLinks'
import Menu from '../Menu'
import Text from '../Text'
import LogoWithDescription from '../LogoWithDescription'

import './style.css'

class FooterWithLogoOnLeftAndNavLinksOnRight extends Component {
    render () {
        const props = this.props.data

        return (
            <section className='footer-with-logo-left-nav-links-right'>
                <div className='main-section'>
                    {props.mainSection.logo && <LogoWithDescription src={props.mainSection.logo.src} alt={props.mainSection.logo.alt || ''} description={props.mainSection.logo.description || ''} />}
                    <div className='menu-and-social-media-links'>
                        {props.mainSection.menu && <Menu list={props.mainSection.menu} />}
                        {props.mainSection.socialMediaLinks && <SocialMediaLinks data={props.mainSection.socialMediaLinks} />}
                    </div>
                </div>
                <hr />
                <div className='secondary-section'>
                    {props.secondarySection && <Text data={{
                        details: props.secondarySection.description,
                        align: props.secondarySection.textAlign
                    }} />}
                </div>
            </section>
        );  
    }
}

export default FooterWithLogoOnLeftAndNavLinksOnRight
