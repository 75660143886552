import React, { Component } from 'react'
import SocialLinks from '../SocialMediaLinks'
import NavigationBlockCollection from '../NavigationBlockCollection'
import Image from '../Image'
import Text from '../Text'

import './style.css'

const componentMapping = {
  'NavigationBlockCollection': NavigationBlockCollection,
  'Image': Image,
  'Text': Text,
  'SocialMediaLinks': SocialLinks
}

class FooterSimple extends Component {
  render () {
    const props = this.props.data
    const primarySection = props.primarySection
    const secondarySection = props.secondarySection

    return (
      <div className='zc-footer-simple'>
        <div className={`main-section ${props.display === 'flex' ? 'flex' : null}`}>
          <div className='left'>
            {primarySection.left.data.map((component, i) => {
              const Component = componentMapping[component.type]
              return (
                <Component key={`footer-primary-simple-${i}`} {...component} />
              )
            })}
          </div>
          <div className='right'>
            {primarySection.right.data.map((component, i) => {
              const Component = componentMapping[component.type]
              return (
                <Component key={`footer-primarty-simple-right-${i}`} {...component} />
              )
            })}
          </div>
        </div>
        <div className='seecondary-section'>
          {secondarySection.data.map((component, i) => {
            const Component = componentMapping[component.type]
            return (
              <Component key={`footer-secondary-simple-${i}`} {...component} />
            )
          })}
        </div>
      </div>
    )
  }
}

export default FooterSimple
