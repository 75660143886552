import React, { Component } from 'react'
import './style.css'

export default class extends Component {
  render () {
    const list = this.props.list
    return (
      <ul className='zc-menu'>
        {list.map((item, i) => (
          <li key={`item-${i}`}>{item.label}</li>
        ))}
      </ul>
    )
  }
}
