import React, { Component } from 'react'
import Text from '../Text'
import Arrow from '../Arrow'
import ImageCardCarousel from '../ImageCardCarousel'

import './style.css'

class ImageCardsWithCarouselOnRight extends Component {
    constructor (props) {
        super(props)

        this.state = {
            currentIndex: 0
        }
    }

    slideNext = () => this.setState({ currentIndex: this.state.currentIndex == this.props.data.carouselData.length - 1 ? 0 : this.state.currentIndex + 1 });

    slidePrev = () => this.setState({ currentIndex: this.state.currentIndex == 0 ? this.props.data.carouselData.length - 1 : this.state.currentIndex - 1 });

    render () {
        const responsive = {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1024: {
                items: 2
            }
        }

        return (
            <div className='ic-with-details-on-left'>
                <div className='ic-details'>
                    <Text data={{
                        title: 'Getting started is easy',
                        details: 'Stand out from the pack with out product Stand out from the pack with' 
                    }} />
                    <div className='ic-arrow-div'>
                        <Arrow 
                            direction='left'
                            clickFunction={ () => this.slidePrev() }
                            glyph='&#9664;'/>
                        <Arrow 
                            direction='right'
                            clickFunction={ () => this.slideNext() }
                            glyph='&#9654;'/>
                    </div>
                </div>
                <div className='image-cards-div'>
                    <ImageCardCarousel
                        responsive={responsive}
                        dotsDisabled={true}
                        responsive={responsive}
                        buttonsDisabled={true}
                        mouseDragEnabled={true}
                        data={this.props.data.carouselData}
                        startIndex={this.state.currentIndex}
                        onSlideChanged={this.props.data.onSlideChanged}
                    />
                </div>
            </div>
        )
    }
}

export default ImageCardsWithCarouselOnRight