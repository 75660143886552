import React, { Component } from 'react'
import ImageCardCarousel from '../ImageCardCarousel'
import Text from '../Text'

import './style.css'

class ContentCardWithImageCards extends Component {
    constructor (props) {
        super(props)
        this.state = {
            mainTitle: this.props.data.imageCardsData[0].title,
            mainDetails: this.props.data.imageCardsData[0].details,
            startIndex: 0
        }
        
        this.onSlideChanged = this.onSlideChanged.bind(this)
    }

    onSlideChanged(e) {
        this.setState({
            mainTitle: this.props.data.imageCardsData[e.item].title,
            mainDetails: this.props.data.imageCardsData[e.item].details,
            startIndex: e.item
        })
    }

    render () {
        const props = this.props.data

        const responsive = {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1024: {
                items: 3
            }
        };
        
        return (
            <div className='content-card-with-image-cards'>
                <div className='ccwic-text-div'>
                    <Text data={{
                        title: this.state.mainTitle,
                        details: this.state.mainDetails
                    }} />
                </div>
                <div className='cc-multiple-image-cards'>
                    {props.imageCardsData.length > 0 && <ImageCardCarousel 
                        responsive={responsive}
                        buttonsDisabled={true}
                        duration={400}
                        autoPlay={true}
                        startIndex = {this.state.startIndex}
                        fadeOutAnimation={true}
                        responsive={responsive}
                        autoPlayInterval={2000}
                        autoPlayDirection="ltl"
                        onSlideChanged={this.onSlideChanged}
                        data={props.imageCardsData}
                    /> }
                </div>
            </div>
        );
    }
}

export default ContentCardWithImageCards