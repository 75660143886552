import React, { Component } from 'react'
import LogoWithDescription from '../LogoWithDescription'
import SocialMediaLinks from '../SocialMediaLinks'
import NavigationBlockCollection from '../NavigationBlockCollection'
import Text from '../Text'

import './style.css'

class FooterWithFormWithTextarea extends Component {
    render () {
        const props = this.props.data
        
        return (
            <section className='footer-with-form-with-textarea'>
                <div className='main-section'>
                    <div className='logo-and-menu'>
                        {props.mainSection.logo && <LogoWithDescription src={props.mainSection.logo.src} description={props.mainSection.logo.description} />}
                    </div>
                    <NavigationBlockCollection data={props.mainSection.navigationBlockCollection} align={props.mainSection.navigationBlockCollection.align} display={props.mainSection.navigationBlockCollection.display} />
                </div> 
                <hr />
                <div className='secondary-section'>
                    {props.secondarySection.socialMediaLinks && <SocialMediaLinks data={props.secondarySection.socialMediaLinks} />}
                    <Text data={{
                        details: props.secondarySection.textOnRight
                    }} /> 
                </div>
            </section>
        )
    }
}

export default FooterWithFormWithTextarea