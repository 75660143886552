import React from 'react'

import './style.css'

const Arrow = ({ direction, clickFunction, glyph, display }) => (
    <div
      className={ `slide-arrow ${direction}` }
      onClick={ clickFunction }
      style={{display: display === true ? 'block': 'none'}}>
      { glyph }
    </div>
);

export default Arrow