import React, { Component } from 'react'

import './style.css'
import VideoCard from '../VideoCard'
import Image from '../Image'
import videoPlaylistImage from '../images/video_card_3.svg'

const VideoCardDiv = ({ src, details, playCurrentVideo, index }) => (
    <div className='video-div' id={index} onClick={playCurrentVideo}>
        <iframe src={src}></iframe>
        <div className='description-div'>
            <p>{details}</p>
        </div>
    </div>
);

class VideoCardPlaylist extends Component {
    constructor (props) {
        super(props)

        if(!Object.keys(this.props.data).length) {
            return
        }

        this.state = {
            currentSrc: this.props.data[0].src,
            currentDetails: this.props.data[0].details
        }

        this.playCurrentVideo = this.playCurrentVideo.bind(this)
    }

    playCurrentVideo (e) {
        const id = parseInt(e.target.closest('.video-div').id)

        this.setState({
            currentSrc: this.props.data[id].src,
            currentDetails: this.props.data[id].details
        });
    }

    render () {
        if(!Object.keys(this.props.data).length) {
            return <Image src={videoPlaylistImage} alt='default-video-playlist' />
        }

        return (
            <div className='video-card-playlist'>
                <VideoCard data={{
                    src: this.state.currentSrc,
                    details: this.state.currentDetails, 
                    autoplay: 1
                }} />
                <div className='playlist-div'>
                    {this.props.data.map((videoCard, index) => (
                        <VideoCardDiv 
                            key={`vcs-${index}`}
                            src={videoCard.src}
                            details={videoCard.details}
                            playCurrentVideo={this.playCurrentVideo}
                            index={index}
                            />
                    ))}
                </div>
            </div>
        );
    }
}

export default VideoCardPlaylist