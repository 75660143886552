import React, { Component } from 'react'

import './style.css'

class TextAsList extends Component {
  render () {
    const content = this.props.data
    return (
      <div className={`zc-text-as-list ${this.props.align === 'right' ? 'tr' : ''}`.trim()}>
        {content.title && <h2>{content.title}</h2>}
        {content.subtitle && <h3>{content.subtitle}</h3>}
        {content.details && <ul>
            {content.details.map((detail, index) => (
                <li key={`details-${index}`}>{detail}</li>
            ))}
        </ul>}
      </div>
    )
  }
}

export default TextAsList
