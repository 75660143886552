import React, { Component } from 'react'
import VideoCard from '../VideoCard'

import './style.css'

class VideoCards extends Component {
    render () {
        return (
            <div className='video-cards'>
                {this.props.data.map((videoCard, index) => (
                    <VideoCard 
                        key={`vc-${index}`}
                        src={videoCard.src}
                        details={videoCard.details}
                        autoplay={0} />
                ))}
            </div>
        )
    }
}

export default VideoCards