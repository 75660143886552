import React, { Component } from 'react'
import Arrow from '../Arrow'

import './style.css'

class ImageSlideShow extends Component {
    constructor (props) {
        super(props)
        this.imageDetails = this.props.data.images
        this.state = {
            currentImageIndex: 0
        };

        this.nextSlide = this.nextSlide.bind(this);
    }

    componentDidMount () {
        setInterval(this.nextSlide, this.props.data.delay ? this.props.data.delay : 5000);
    }

    nextSlide (slideTo = 'next') {
        const lastIndex = this.imageDetails && this.imageDetails.length - 1;
        const { currentImageIndex } = this.state;
        let shouldResetIndex = currentImageIndex === lastIndex;
        let index =  shouldResetIndex ? 0 : currentImageIndex + 1;
        if (slideTo === 'previous') {
            shouldResetIndex = currentImageIndex === 0;
            index =  shouldResetIndex ? lastIndex : currentImageIndex - 1;
        } 
    
        this.setState({
          currentImageIndex: index
        });
    }

    changeSlide (index) {
        this.setState({
            currentImageIndex: index
        });
    }

    render () {
        const imagesData = this.props.data && this.props.data.images
        if((imagesData || []).length === 0) return null

        return (
            <div className='image-slide-show'>
                <div className='carousel-wrapper'>
                    <Arrow
                        direction='left'
                        clickFunction={() => this.nextSlide('previous') }
                        glyph='&#9664;' 
                        display={this.props.data.arrows} />
                    <div className='images-div'>
                        {
                            imagesData && imagesData.map((image, index) => (
                                <a key={`image-${index}`} 
                                    href={`${image.link && image.link.indexOf('/') === 0 ? image.link : '/' + image.link}`}
                                    className={index === this.state.currentImageIndex ? 'active' : ''} 
                                    style={{backgroundImage: `url(${imagesData[index]['imageUrl']})`}}>
                                </a>
                            ))
                        }
                    </div>
                    <Arrow
                        direction='right'
                        clickFunction={() =>  this.nextSlide('next') }
                        glyph='&#9654;'
                        display={this.props.data.arrows} />
                </div>
                <ul className='carousel-indicators' style={{display: this.props.data.dots === false ? 'none' : ''}}>
                    {
                        imagesData && (imagesData.length > 1) && imagesData.map((list, index) => (
                            <li key={`list-${index}`}
                                onClick={() => this.changeSlide(index)}
                                className={index === this.state.currentImageIndex ? 'active' : ''} >
                            </li>
                        ))
                    }
                </ul>
            </div>
        )
    }
}

export default ImageSlideShow
