import React, { Component } from 'react'

import './style.css'

class TabsCard extends Component {
    render () {
        const { expanded, title, details, onToggle } = this.props
        let containerStyle = !expanded || this.contentRef ? {
            'maxHeight': (expanded ? this.contentRef.offsetHeight + 50 + 'px' : 0)
        } : {}
        return (
            <div className={`tabs-card ${expanded ? 'expanded' : ''}`.trim()}>
                <div className='tabs-card-title'>
                    <h4 onClick={onToggle}>{title}</h4>
                    <div onClick={onToggle} className={`${expanded ? 'up-arrow' : 'down-arrow'}`.trim()}>&nbsp;</div>
                </div>
                <div className='content-container'
                    style={containerStyle}
                >
                    <div className={`tabs-card-details muted`} ref={node => { this.contentRef = node }}>
                        <p>{details}</p>
                    </div>
                </div>
            </div>
        );
    }    
} 

class TabsCardSlider extends Component {
    constructor (props) {
        super(props)
        this.state = {
            expandedIndex: null
        }
        
        this.toggle = this.toggle.bind(this)
    }

    toggle (index) {
        this.setState(prevState => {
            let expandedIndex = prevState.expandedIndex === index ? null : index
            return {
                expandedIndex
            }
        })
    }

    render () {
        let _this = this
        let expandedIndex = this.state.expandedIndex
        
        return (
            <div className='tabs-card-slider'>
                {this.props.data.map((cardDetails, index) => (
                    <TabsCard
                        key={`tabs-card-${index}`}
                        onToggle={() => _this.toggle(index)}
                        expanded={expandedIndex === index}
                        title={cardDetails.title}
                        details={cardDetails.details}
                    />
                ))}
            </div>
        );
    }
}

export default TabsCardSlider