import React, { Component } from 'react'

import './style.css'

class Form extends Component {
    constructor (props) {
        super()
        this.state = {
          status: '' 
        }
        this.submitForm = this.submitForm.bind(this)
    }

    submitForm(e) {

    }

    render () {
        const props = this.props.data
        return (
            <form onSubmit={this.submitForm} method='POST' id='contact-us-form'>
                {props.map((item, index) => (
                    <item.element key={`form-item-${index}`} type={item.type} name={item.name} placeholder={item.placeholder} />
                ))}
                <input className='submit-button' type='submit' value='submit' />
            </form>
        );
    }
}

export default Form