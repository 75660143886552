import React, {Component} from 'react'
import ImageCard from '../ImageCard'

import './style.css'

class ImageCardsWithTextAsList extends Component {
    render() {
        const props = this.props.data

        return (
            <div className='image-cards-with-text-as-list'>
                { this.props.data.map((item, index) => (
                    <ImageCard key={`image-card ${index}`} data={{
                        src: item.src,
                        title: item.title,
                        details: item.details,
                        textType: item.textType,
                        align: item.textAlign
                    }} />
                ))}
            </div>
        )
    }
}

export default ImageCardsWithTextAsList