import React, { Component } from 'react'
import ImageWithText from '../ImageWithText'
import './style.css'

class ImageWithTextCollection extends Component {
  render () {
    const props = this.props.data
    return (
      <section className='zc-image-with-text-collection'>
        <h2 className='z-heading'>{props.title}</h2>
        <div>
          {props.collection.map((item, i) => (
            <article key={`item-${i}`}>
              <ImageWithText data={{
                src: item.src,
                alt: item.alt,
                title: item.title,
                subtitle: item.subtitle,
                details: item.details,
                align: props.align
              }} />
            </article>
          ))}
        </div>
      </section>
    )
  }
}

export default ImageWithTextCollection
