import React from 'react'
import Logo from '../Logo'
import Text from '../Text'

import './style.css'

const LogoWithDescription = ({src, alt, description}) => (
    <div className='logo-with-description'>
        <Logo src={src} alt={alt} />
        <Text data={{
            details: description
        }} />
    </div>
);

export default LogoWithDescription