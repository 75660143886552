import React, { Component } from 'react'
import Image from '../Image'
import Text from '../Text'

import './style.css'

class ImageCardWithTextOnRight extends Component {
    render () {
        let props = this.props.data 
        return (
            <div className='image-card-with-text-on-right'>
                <Image src={props.src} alt={props.alt1} />
                <Text data={{
                    title: props.title,
                    details: props.details
                }} />
            </div>
        )
    }
}

export default ImageCardWithTextOnRight