import React, { Component } from 'react'
import ImageCardWithTitleOnRight from '../ImageCardWithTitleOnRight'

import './style.css'

class ImageCardsWithTitleOnRight extends Component {
    render () {
        return (
            <div className='image-cards-with-title-on-right'>
                { this.props.data.map((item, index) => (
                    <ImageCardWithTitleOnRight key={`icwtor-${index}`} data={{
                        src: item.src,
                        title: item.title,
                        details: item.details
                    }} />
                ))}
            </div>
        )
    }
}

export default ImageCardsWithTitleOnRight