import React, { Component } from 'react'
import Text from '../Text'

import './style.css'

class VideoCard extends Component {
    render () {
      const content = this.props.data
  
      return (
        <div className='video-card'>
            <div className='video-div'>
                <iframe src={`${content.src}?autoplay=${content.autoplay}`}></iframe>
            </div>
            <Text data={{
                details: content.details,
                textColorForDescription: content.textColorForDescription,
                fontSizeforDescription: content.fontSizeforDescription
            }} />
        </div>
      )
    }
  }

export default VideoCard