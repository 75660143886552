import React, { Component } from 'react'
import Form from '../Form'

import './style.css'

class NavigationBlock extends Component {
  render () {
    const props = this.props.data
    
    return (
      <div className={`zc-navigation-block`}>
        <h3>{props.title}</h3>
        {props.links && <ul>
          {props.links.map((link, index) => (
            link.type && link.type == 'form' ? 
              <Form key={`input-${props.title}-${index}`} data={link.formData} />
            :
              <li key={`li-${props.title}-${index}`}><a href={link.href}>{link.title}</a></li>
          ))}
        </ul>}
      </div>
    )
  }
}
export default NavigationBlock
